<template>
  <mds-page-shell
    :logo="require('@/assets/logos/morningstar.svg')"
    :logo-responsive="require('@/assets/logos/morningstar.svg')"
    logo-href="/"
    logo-alt="Back to home page"
  >
    <template #mds-page-shell-masthead-right>
      <div class="rigth-content">
        <env-tag />
      </div>
    </template>
    <template #default>
      <mds-layout-grid>
        <sign-in-form />
      </mds-layout-grid>
    </template>
  </mds-page-shell>
</template>

<script>
import MdsPageShell from '@mds/page-shell';
import SignInForm from '@/components/sign-in/form/SignInForm';
import EnvTag from '@/components/ui/EnvironmentTag.vue';

export default {
  name: 'SignInPage',
  components: {
    MdsPageShell,
    SignInForm,
    EnvTag,
  },
};
</script>

<style lang="scss" scoped>
.rigth-content {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-start;
}
</style>
