<template>
  <mds-row class="sign-in">
    <mds-col :cols="3">
      <h1 class="sign-in__header">
        Sign In
      </h1>
      <validation-observer
        v-slot="{ handleSubmit, invalid }"
        slim
      >
        <mds-form
          class="sign-in__form"
          @submit.native.prevent="handleSubmit(onSubmit)"
        >
          <mds-alert
            v-if="hasFormErrors"
            class="sign-in__alert"
            variation="error"
            title="The username or password is incorrect."
            persistent
          >
            Please try again.
          </mds-alert>

          <validation-provider
            v-slot="{ errors, failed }"
            name="Username"
            rules="required"
            slim
          >
            <mds-input
              v-model="auth.username"
              :error="failed"
              :error-text="errors"
              autocomplete="username"
              label="Username"
              type="text"
            />
          </validation-provider>
          <validation-provider
            v-slot="{ errors, failed }"
            name="Password"
            rules="required"
            slim
          >
            <mds-input
              v-model="auth.password"
              :error="failed"
              :error-text="errors"
              autocomplete="current-password"
              password
              label="Password"
              type="password"
            />
          </validation-provider>
          <mds-button-container right-aligned>
            <mds-button
              variation="primary"
              :disabled="invalid"
              text="Sign In"
              :loading="isFormLoading"
            />
          </mds-button-container>
        </mds-form>
      </validation-observer>
    </mds-col>
  </mds-row>
</template>

<script>
import MdsForm from '@mds/form';
import MdsAlert from '@mds/alert';
import MdsInput from '@mds/input';
import { MdsButton, MdsButtonContainer } from '@mds/button';
import { MODULE } from '@/constants/store.constant';
import AUTH from '@/constants/auth.constant';
import ROUTE from '@/constants/routes.constant';

export default {
  name: 'SignInPage',
  components: {
    MdsForm,
    MdsInput,
    MdsButtonContainer,
    MdsButton,
    MdsAlert,
  },
  data () {
    return {
      auth: {
        username: '',
        password: '',
      },
      isFormLoading: false,
      hasFormErrors: false,
    };
  },
  methods: {
    async onSubmit () {
      this.hasFormErrors = false;
      try {
        this.isFormLoading = true;
        await this.$store.dispatch(`${MODULE.AUTH}/${AUTH.LOG_IN}`, this.auth);
        this.$router.push(this.$route.query.redirect || { name: ROUTE.DAP });
      } catch (error) {
        this.hasFormErrors = true;
        this.auth.password = '';
      } finally {
        this.isFormLoading = false;
      }
    },
  },
  route: ROUTE,
};
</script>

<style lang="scss" scoped>
@import '@mds/typography';

.sign-in {
  display: flex;
  justify-content: center;
  margin: $mds-space-8-x auto;

  &__form {
    margin: $mds-space-4-x 0;
  }

  &__header {
    @include mds-level-2-heading();
  }

  &__alert {
    margin: $mds-space-4-x 0;
  }
}
</style>
